import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, change, reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';

import ModalWindow from '../../ModalWindow';
import { Preloader } from '../../../components';
import { TagField, DeviceNameField, DescriptionTagField, CustomSelect } from '../../../components/ReduxFormFields';
import { PrimaryButton, CancelButton } from '../../../components/UIKit';
import RenderFieldsByDevName from '../components/RenderFieldsByDevName';

import { getSensorsConfigurationFile, saveDevicesTypesList } from '../slice';
import { additionalFields, devicesInitialValues, getNamesByType } from '../utils';
import { required } from '../../validation';
import { editDeviceFormId } from '../constants';
import { devicesGetTypesAndNames } from '../../UserDetails/slice';

import {
  isExpiredSensorsConfigurationFileSelector,
  editDeviceFormDevice_group,
  editDeviceFormData,
  editDeviceFormType,
  editDeviceIsCarExists,
  getRestrictedTypesSelector
} from './selectors';
import { getSignInUserRoleTypeSelector } from '../../../redux-store/selectors/signIn';
import { allDevicesTypesList, sensorsConfigurationFileSelector } from '../../../redux-store/selectors/devicesTable';
import { getModalsIsLoadingSelector, getModalsSelector } from '../../../redux-store/selectors/modals';
import { getSensorsOauth2IsDisabledOauth2EditModalSelector } from '../../../redux-store/selectors/sensorsOauth2';
import { EDIT_ROW_MODAL_ID, UPGRADE_TO_UNLOCK_POPUP, closeModalWindow, openModalWindow } from '../../ModalWindow/slice';
import { useAdditionalValuesForFields } from '../hooks/useAdditionalValuesForFields';
import i18n from '../../../i18n';
import { getUserLicenseSelector } from '../selectors';
import { getUserDevicesUser_IdSelector } from '../../../redux-store/selectors/userDevices';

/**
 * EditDeviceWindow form id
 */
const formID = editDeviceFormId;

/**
 * Modal window for editing device
 * @memberof module:UserDevices
 */
const EditDeviceWindow = (props) => {
  const {
    handleSubmit,
    deviceType,
    dispatch,
    pristine,
    initialValues,
    reset,
    userApp,
    license
  } = props;
  const devicesList = useSelector(allDevicesTypesList);

  const type = useSelector(editDeviceFormType);
  const isCarExists = useSelector(editDeviceIsCarExists);
  const data = useSelector(editDeviceFormData);
  const modals = useSelector(getModalsSelector);
  const isLoading = useSelector(getModalsIsLoadingSelector);
  const device_group = useSelector(editDeviceFormDevice_group);
  const myRoleType = useSelector(getSignInUserRoleTypeSelector);
  const sensorsConfigurationFile = useSelector(sensorsConfigurationFileSelector);
  const isDisabledOauth2EditModal = useSelector(getSensorsOauth2IsDisabledOauth2EditModalSelector);
  const isExpiredSensorsConfigurationFile = useSelector(isExpiredSensorsConfigurationFileSelector);
  const modal = modals?.[EDIT_ROW_MODAL_ID];
  const newDeviceType = modal?.data?.newType || deviceType;
  const isShowDeviceActivity = newDeviceType !== 'sub-meter';
  const tagColor = modal?.data?.tagColor;
  const supportUrl = devicesInitialValues[device_group]?.data?.support_url;
  const userLicense = useSelector(getUserLicenseSelector);
  const userId = useSelector(getUserDevicesUser_IdSelector);
  const restrictedDeviceTypes = useSelector(getRestrictedTypesSelector);

  const handleOnClose = () => dispatch(closeModalWindow({ modalID: EDIT_ROW_MODAL_ID }));

  useAdditionalValuesForFields({ data, formID });
  useEffect(() => {
    if (modal?.data?.type === 'Battery' && (!data.socLimit || [15, 95].includes(data.socLimit))) {
      if (data?.batteryChargingMode === 2) {
        dispatch(change(formID, 'data.socLimit', 95));
      }

      if (data?.batteryChargingMode === 3) {
        dispatch(change(formID, 'data.socLimit', 15));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.batteryChargingMode]);

  useAdditionalValuesForFields({ data, formID });
  useEffect(() => {
    if (modal?.data?.type === 'Battery' && (!data.socLimit || [15, 95].includes(data.socLimit))) {
      if (data?.batteryChargingMode === 2) {
        dispatch(change(formID, 'data.socLimit', 95));
      }

      if (data?.batteryChargingMode === 3) {
        dispatch(change(formID, 'data.socLimit', 15));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.batteryChargingMode]);

  useAdditionalValuesForFields({ data, formID });
  useEffect(() => {
    if (modal?.data?.type === 'Battery' && (!data.socLimit || [15, 95].includes(data.socLimit))) {
      if (data?.batteryChargingMode === 2) {
        dispatch(change(formID, 'data.socLimit', 95));
      }

      if (data?.batteryChargingMode === 3) {
        dispatch(change(formID, 'data.socLimit', 15));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.batteryChargingMode]);

  useAdditionalValuesForFields({ data, formID });
  useEffect(() => {
    if (modal?.data?.type === 'Battery' && (!data.socLimit || [15, 95].includes(data.socLimit))) {
      if (data?.batteryChargingMode === 2) {
        dispatch(change(formID, 'data.socLimit', 95));
      }

      if (data?.batteryChargingMode === 3) {
        dispatch(change(formID, 'data.socLimit', 15));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.batteryChargingMode]);

  useAdditionalValuesForFields({ data, formID });
  useEffect(() => {
    if (modal?.data?.type === 'Battery' && (!data.socLimit || [15, 95].includes(data.socLimit))) {
      if (data?.batteryChargingMode === 2) {
        dispatch(change(formID, 'data.socLimit', 95));
      }

      if (data?.batteryChargingMode === 3) {
        dispatch(change(formID, 'data.socLimit', 15));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.batteryChargingMode]);

  useAdditionalValuesForFields({ data, formID });
  useEffect(() => {
    if (modal?.data?.type === 'Battery' && (!data.socLimit || [15, 95].includes(data.socLimit))) {
      if (data?.batteryChargingMode === 2) {
        dispatch(change(formID, 'data.socLimit', 95));
      }

      if (data?.batteryChargingMode === 3) {
        dispatch(change(formID, 'data.socLimit', 15));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.batteryChargingMode]);

  useAdditionalValuesForFields({ data, formID });
  useEffect(() => {
    if (modal?.data?.type === 'Battery' && (!data.socLimit || [15, 95].includes(data.socLimit))) {
      if (data?.batteryChargingMode === 2) {
        dispatch(change(formID, 'data.socLimit', 95));
      }

      if (data?.batteryChargingMode === 3) {
        dispatch(change(formID, 'data.socLimit', 15));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.batteryChargingMode]);

  useAdditionalValuesForFields({ data, formID });
  useEffect(() => {
    if (modal?.data?.type === 'Battery' && (!data.socLimit || [15, 95].includes(data.socLimit))) {
      if (data?.batteryChargingMode === 2) {
        dispatch(change(formID, 'data.socLimit', 95));
      }

      if (data?.batteryChargingMode === 3) {
        dispatch(change(formID, 'data.socLimit', 15));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.batteryChargingMode]);

  useAdditionalValuesForFields({ data, formID });

  useEffect(() => {
    if (modal?.opened && (!sensorsConfigurationFile || isExpiredSensorsConfigurationFile)) {
      dispatch(getSensorsConfigurationFile());
    }

    if (!modal?.opened) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.opened]);

  const onSubmit = () => {
    const allowedDeviceTypes = userLicense?.restrictions?.allowedDeviceTypes;
    if ((allowedDeviceTypes?.length && !allowedDeviceTypes.includes(type)) || restrictedDeviceTypes.includes(type)) {
      return dispatch(openModalWindow({ modalID: UPGRADE_TO_UNLOCK_POPUP }));
    }

    return handleSubmit();
  };

  useEffect(() => {
    if (!modal?.opened && ['root', 'solar_admin'].includes(myRoleType)) {
      dispatch(devicesGetTypesAndNames({
        deviceType: newDeviceType,
        userId,
        cb: (result) => saveDevicesTypesList({ data: result })
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.opened, newDeviceType, devicesGetTypesAndNames, myRoleType]);

  return (
    <ModalWindow modalID={EDIT_ROW_MODAL_ID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('editDevice')}</h5>
      </div>
      {isLoading ? (
        <div className="admin-preloader-container">
          <Preloader />
        </div>
      ) : (
        <>
          <div className="modal-body">
            <form
              id={formID}
              className="m-login__form m-form pop-up-form add-sm-us"
            >
              <div className="form-group m-form__group input-field zIndex21">
                <div className="flags-select-label">{`${i18n.t('deviceType')} *`}</div>
                <Field
                  name="type"
                  component={CustomSelect}
                  placeholder={i18n.t('deviceType')}
                  options={[{ value: type, label: i18n.exists(type) ? i18n.t(type) : type }]}
                  validate={[required]}
                  className="m-input"
                  disabled
                />
              </div>
              <DeviceNameField
                deviceName={device_group}
                disabled={!['root', 'solar_admin'].includes(myRoleType)}
                myRoleType={myRoleType}
                newDeviceType={newDeviceType}
                form={editDeviceFormId}
                currentId={initialValues?.id || initialValues?._id}
                labelDeviceGroup={`${i18n.t('deviceName')} *`}
                placeholder={i18n.t('deviceName')}
                namesList={['root', 'solar_admin'].includes(myRoleType) ? getNamesByType(
                  [devicesList ?? [], [{ name: device_group, type }]].flat(),
                  type,
                  license?.restrictions?.allowedDeviceGroups
                ) : [{ value: device_group, label: i18n.t(device_group) }]}
                support_url={supportUrl}
                tagColor={tagColor}
              />
              {['sub-meter'].includes(newDeviceType) && (
                <>
                  <Field name="data.subMeterCostTypes" {...additionalFields.subMeterCostTypes} />
                  <br />
                </>
              )}
              <DescriptionTagField deviceType={newDeviceType} />
              <TagField
                deviceType={newDeviceType}
                formID={formID}
              />
              {['root', 'solar_admin', 'pv_installer', 'pv_installer_employee', 'pv_installer_employee_read_install', 'property_management', 'oem', 'end_user', 'oem_employee', 'viewer'].includes(myRoleType) ? (
                <RenderFieldsByDevName
                  deviceName={device_group}
                  data={data}
                  modal={modal}
                  myRoleType={myRoleType}
                  type={type}
                  formID={formID}
                  isShowDeviceActivity={isShowDeviceActivity}
                  newDeviceType={newDeviceType}
                  mode="edit"
                  isCarExists={isCarExists}
                  license={license || {}}
                  userApp={userApp}
                />
              ) : null}
            </form>
          </div>
          <div className="modal-footer">
            <CancelButton onClickHandler={handleOnClose} />
            <PrimaryButton disabled={pristine && isDisabledOauth2EditModal} type="save" onClickHandler={onSubmit} />
          </div>
        </>
      )}
    </ModalWindow>
  );
};

EditDeviceWindow.propTypes = {
  initialValues: PropTypes.instanceOf(Object).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  deviceType: PropTypes.string.isRequired,
  pristine: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  userApp: PropTypes.string.isRequired,
  license: PropTypes.instanceOf(Object)
};

const form = reduxForm({
  form: formID,
  enableReinitialize: true
})(EditDeviceWindow);

export default form;
